.slider {
    width: 50%;
    height: 50%;
    box-sizing: border-box;
    padding: 20px;
}

.circular-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: #222;

    .wrapper {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        position: relative;
        box-sizing: border-box;
        padding: 20px 20px 0px 20px;
        overflow: hidden;

        .controls {

            &__left, &__right, &__autoplay {
                position: absolute;
                z-index: 101;
                transition: .6s all;

                &:hover {

                    .icon-wrapper {
                        font-size: 1.7em;
                        opacity: 1;
                    }

                }

                .icon-wrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center; 
                    width: 100%;
                    height: 100%;
                    color: #8EB8E5;
                    font-size: 1.5em;
                    opacity: .7;
                }

            }

            &__left, &__right {
                top: 50%;
            }

            &__left {
                left: 0;
                transform: translateY(-50%);
                
                &:hover {
                    left: 0;
                }

            }

            &__right {
                right: 0;
                transform: translate( 0%, -50% );
            }

            &__autoplay {
                bottom: 0;
                left: 50%;
                transform: translate( -50%, 0% );

                &_running {

                    .pause {
                        display: block;
                    }

                    .run {
                        display: none;
                    }

                }

                &_paused {

                    .pause {
                        display: none;
                    }

                    .run {
                        display: block;
                    }

                }

            }

        }

        .slides-holder {
            border-radius: 50%;
            border: 2px solid #8EB8E5;
            //transition: cubic-bezier(.28,.94,.53,1.19) all;
            transform-origin: center;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 100;

            &__item {
                border-radius: 50%;
                border: 2px solid #7C99B4;
                position: absolute;
                box-sizing: border-box;
                transform-origin: center;
                background-color: #222;
                transition: .3s linear all;
                filter: brightness(70%);

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    user-select: none;
                }

                &_active {
                    filter: brightness(100%);
                }

            }

        }

        .descriptions {
            position: absolute;
            bottom: 0%;
            z-index: 0;

            &__item {
                width: 100%;
                height: 0%;
                opacity: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                transition: opacity 0s 0s linear;

                &_visible {
                    height: 100%;
                    opacity: 1;
                    transition: opacity .6s 0s linear;
                }

                h1, .description {
                    font-family: Helvetica, sans-serif;
                    color: white;
                    text-align: center;
                }

                h1 {
                    font-size: 200%;
                    padding-top: 5px;
                }

                .description {
                    font-size: 100%;
                    margin-top: 5px;
                    padding: 0% 10%;
                    text-overflow: ellipsis;
                    overflow-y: hidden;
                }

            }

        }

    }

}